<template>
  <!--
    The view for the ActionCam Configuration
  -->
  <div class="installationDetail_ActionCam">
    <template>
      <div class="card">
        <ActioncamList
          :installation-id="installationId"
        />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "InstallationDetailActioncam",
  components: {
    ActioncamList: () => import('@/components/Actioncam/ActioncamList.vue')
  },
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
    }
  },
  metaInfo () {
    return {
      title: 'Actioncam'
    }
  }
}
</script>